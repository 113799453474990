import React from "react";
import styled from "styled-components";

function Section2() {
  return (
    <Container>
      <Wrap>
        <SubWrapper>
          <Left>
            <Title>What is KaijuVerse?</Title>
            <Description>
              <p>
                On one thunderous evening a bolt of lightning struck the waters
                of the Pacific and from there emerged the Kaijus.Each of these
                Kaijus possessed special abilities which led to destruction and
                only destruction.
              </p>

              <p>
                Not satisfied with causing complete havoc on earth they now roam
                the metaverse. After multiple portals opened up in the metaverse
                1,313 Kaijus have entered the digital lands causing complete
                annihilation in the digital cities of the metaverse.
              </p>

              <p>
                From now on Kaiju attacks will be a common occurrence in the
                metaverse.
              </p>
            </Description>
          </Left>
          <Right>
            <img src="assests/Kaiju.gif" alt="" />
          </Right>
        </SubWrapper>
      </Wrap>
    </Container>
  );
}

export default Section2;

const Container = styled.section`
  min-height: 10vh;
  background: #02080d;
  // min-width: 100vw;
  padding-top: 80px;
  @media (max-width: 800px) {
    padding-top: 0px;
  }
`;

const Wrap = styled.div`
  margin: 64px 64px;
  display: flex;
  justify-content: center;
  @media (max-width: 800px) {
    margin: 64px 32px;
  }
`;

const SubWrapper = styled.div`
  max-width: 1100px;
  width: 100%;
  min-width: 200px;
  display: flex;
  justify-content: space-evenly;
  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Left = styled.div``;

const Title = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  color: #9000ff;
  @media (max-width: 1100px) {
    font-size: 36px;
  }
`;

const Description = styled.div`
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 27px;
  letter-spacing: 0em;

  text-align: justified;
  color: #ffffff;
  max-width: 610px;
  width: 100%;
  min-width: 200px;
  @media (max-width: 1100px) {
    font-size: 18px;
  }
`;

const Right = styled.div`
  img {
    width: 410px;
    height: 410px;
  }
  @media (max-width: 1100px) {
    img {
      max-width: 410px;
      max-height: 410px;
      width: 100%;
      height: auto;
      min-width: 240px;
      min-height: 240px;
    }
  }
  @media (max-width: 800px) {
    margin-top: 64px;
  }
`;
