import React from "react";
import styled from "styled-components";
import Navbar from "../Navbar";
import "./styles.css";

function Section1() {
  return (
    <Section>
      <Navbar />
      <Intro>
        <Title>
          An Army Of 1313 Kaiju's.<br></br>
          Enlist Now
        </Title>
        {/* <Countdown>Mint price: 1.5 SOL</Countdown> */}
        <MintButton>
          <a href="https://discord.gg/kaijuverse">
            <p className="landing__btn">Join Discord</p>
          </a>
        </MintButton>
      </Intro>
    </Section>
  );
}

/*function Section1() {
  
  const [error, setError] = React.useState(false)
  const [showMint, setShowMint] = React.useState(false)
  const [value, setValue] = React.useState()

  const validList = [
    {
      id: 'hjfd768rhbnsdbtry48rk'
    }, 
    {
      id: 'd2s4dsdcds5sd6v7s4vc6'
    }, 
    {
      id: '1q2s3d42d3v42s3c4d34v'
    }, 
  ]

  const onSubmit = (e) => {
    e.preventDefault()
    const condition = Boolean(validList.find(data => data.id === value))
    if (condition) {
      setShowMint(true)
      setError(false)
    } else {
      setShowMint(false)
      setError(true)
    }
    console.log(condition)
  }

  const inputForm = (e) => {
    const wallet = e.target.value
    setValue(wallet)
    console.log(wallet)
  }

  return (
    <Section>
      <Navbar />
      <Intro>
        <Title>4,444 Other Invaders looking for a new home.</Title>
        <ValidWrap>
          {
            showMint ? 
              "":
            <CheckValidity>
              <input name='walletid' id='walletid' onChange={inputForm} type="text" placeholder='Enter wallet address to check for presale eligibility' />
              <button onClick={onSubmit} className='check'>Check</button>
            </CheckValidity>
          }
          {error ? 
            <div 
            style={{ display: "flex", justifyContent: 'flex-start', flex: 1, width: '100%', margin: '6px 2px', color: 'red', fontSize: '1.2em' }} 
            className="message">ERROR: Wallet Address Not Found</div>
            : ''
          }
        </ValidWrap>
        <Countdown>Presale Mint 11/12 9PM UTC</Countdown>
        <MintButton style={{ display: showMint ? 'flex' : 'none'}}><p id="demo"></p></MintButton>
      </Intro>
    </Section>
  );
}
*/

export default Section1;

const Section = styled.section`
  min-height: 100vh;
  // min-width: 100vw;
  background-image: url(/assests/landing.png);
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
  background-position: center;
  background-size: cover;
  display: flex;
  flex: 1;
  flex-direction: column;
  // justify-content: center;
`;

const Intro = styled.div`
  display: flex;
  // flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  @media (max-width: 700px) {
    margin-top: 80px;
  }
  @media (max-width: 300px) {
    margin-left: 26px;
    margin-right: 26px;
  }
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 1000;
  font-size: 48px;
  color: white;
  // padding-left: 20px;
  // padding-right: 20px;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  letter-spacing: 0.03em;
  width: 100%;
  max-width: 1200px;
  min-width: 240px;
  margin-bottom: 15px;
  margin-top: 80px;
  @media (max-width: 700px) {
    font-size: 36px;
  }
`;

const ValidWrap = styled.div`
  max-width: 550px;
  width: 100%;
  min-width: 100px;
  margin: 32px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .message {
    font-family: Poppins;
  }
`;

const CheckValidity = styled.form`
  border: 3px solid white;
  display: flex;
  max-width: 550px;
  width: 100%;
  min-width: 100px;
  /* margin: 32px 16px; */
  input {
    outline-width: 0;
    border: none;
    flex: 1;
    display: flex;
    font-size: 1.2em;
    /* width: 100%; */
    font-weight: 500;
    color: white;
    background: inherit;
    padding: 12px;
  }
  .check {
    font-size: 1.2em;
    font-weight: 500;
    background-color: green;
    border: none;
    color: white;
    padding: 12px 16px;
    border-left: 3px solid white;
  }

  @media (max-width: 650px) {
    flex-direction: column;
    max-width: 450px;
    .check {
      border-left: 0px;
      border-top: 3px solid white;
    }
  }
  @media (max-width: 450px) {
    max-width: 320px;
  }
`;

const Countdown = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 1000;
  font-size: 24px;
  color: white;
  text-align: center;
  font-family: Poppins;
  margin-bottom: 64px;
`;

const MintButton = styled.button`
  display: flex;
  color: #ffffff;
  // flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 36px;
  font-family: Poppins;
  //width: 250x;
  //height: 76px;
  background: #9000ff;
  font-weight: 600;
  cursor: pointer;
  font-size: 18px;
  transition: 0.5s;
  :hover {
    background: #c67cff;
  }
`;

const Desc = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 1000;
  font-size: 18px;
  color: white;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  letter-spacing: 0.03em;
  width: 100%;
  max-width: 614px;
  min-width: 240px;
  margin-top: 64px;
  @media (max-width: 700px) {
    font-size: 36px;
  }
`;
