import React from "react";
import styled from "styled-components";

function Section6() {
  return (
    <Container>
      <Wrap>
        <a href="/">
          <img src="assests/Logo.png" alt="" />
        </a>
        <Desc>An Army Of 1313 Kaiju's. Enlist Now!</Desc>
        <Social>
          <a href="https://discord.gg/kaijuverse">
            <Box>
              <img src="assests/Vector.png" alt="" />
            </Box>
          </a>
          <a href="https://twitter.com/KaijuVerseNFT">
            <Box>
              <img src="assests/twitter.png" alt="" />
            </Box>
          </a>
        </Social>
        <Security>@2022 Kaiju Verse. All rights reserved.</Security>
      </Wrap>
    </Container>
  );
}

export default Section6;

const Container = styled.div`
  background: #02080d;
`;

const Wrap = styled.div`
  margin: 64px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    margin: 16px 0;
    width: 150px;
  }
`;

const Desc = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  margin: 16px 0;
  color: #ffffff;
`;

const Social = styled.div`
  display: flex;
`;

const Box = styled.div`
  width: 36px;
  height: 36px;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 12px;
  transition: 0.3s;
  :hover {
    background: #9000ff !important;
  }
  img {
    height: 12px;
    width: 16px;
    border-radius: 0px;
  }
`;

const Security = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  text-align: center;
  color: #ffffff99;
  margin: 32px 0;
`;
