import React from "react";
import styled from "styled-components";
import Fade from "react-reveal";
import "./styles.css";

function Section4() {
  const [scrollElement, setScrollElement] = React.useState({
    Phase1: "0%",
    Phase2: "0%",
    Phase3: "0%",
    Phase4: "0%",
    Phase5: "0%",
  });

  const scrollUpdate = () => {
    let current = "";
    const sections = document.querySelectorAll("section");
    const Progress = document.getElementById("Progress");
    const { pageYOffset } = window;
    const progressTop = Progress.offsetTop;
    const progressheight = Progress.offsetHeight;
    sections.forEach((section) => {
      const sectionTop = section.offsetTop;
      const sectionHeight = section.offsetHeight;

      if (pageYOffset >= sectionTop - sectionHeight) {
        current = section.getAttribute("id");
        if (current) {
          const sec = document.getElementById(current);
          const secTop = sec.offsetTop;
          const secHeight = sec.offsetHeight;
          let value = Math.round(window.scrollY + 250 - sec.offsetTop);
          console.log(current, value);
          // console.log((pageYOffset/(sectionTop-sectionHeight/6)))
          const updatedValue = {};

          if (value < 100 && value > 0) {
            value = value.toString() + "%";
          } else if (value > 100) {
            value = "100%";
          } else {
            value = "0%";
          }
          setScrollElement((prev) => ({ ...prev, [current]: value }));
        }
      } else if (pageYOffset <= progressTop - progressheight) {
        setScrollElement((prev) => ({
          ...prev,
          Phase1: "0%",
          Phase2: "0%",
          Phase3: "0%",
          Phase4: "0%",
          Phase5: "0%",
        }));
      }
    });
  };

  React.useEffect(() => {
    scrollUpdate();
    window.addEventListener("scroll", scrollUpdate);
    return () => {
      window.removeEventListener("scroll", scrollUpdate);
    };
  }, []);
  console.log(scrollElement);

  return (
    <Container>
      <Wrap>
        <Title>
          Roadmap <br></br>(Minimum Bullshit, We Promise)
        </Title>
        <Description>
          We have developed this roadmap, to help our community understand our
          entire chain of operations for the foreseeable future, while also
          trying to keep it short so to save time from having to read bullshit
          that we're never going to do.
        </Description>
        <ProgressWrap id="Progress">
          {/* <Fade bottom> */}
          <Phase id="Phase1">
            <Left>
              <Box height={scrollElement.Phase1}>
                <Rect height={scrollElement.Phase1} />
              </Box>
              <Stick>
                <Progress height={scrollElement.Phase1} />
              </Stick>
            </Left>
            <Right height={scrollElement.Phase1}>
              <Name height={scrollElement.Phase1}>Building our army</Name>
              <Desc height={scrollElement.Phase1}>- The Ascendancy</Desc>
              <Content height={scrollElement.Phase1}>
                We are a team, which believes in trust, transparency and most
                importantly crushing goals together. This is why we are striving
                to build a community with the similar values. We plan to build
                this community organically, through our social media and
                collaborations. This will be supported by our high quality
                collection, developments and utility. Since we value and
                prioritise support in the KaijuVerse, we will carefully select
                and build our army of supporters. These supporters will be
                rewarded by our moderators and will be getting early access to
                sneak peeks and whitelists. We have special ranks in our Kaiju
                army, for loyal and consistent supporters, that is why we will
                have special ranks in the community for them.
              </Content>
            </Right>
          </Phase>
          <Phase id="Phase2">
            <Left>
              <Box height={scrollElement.Phase2}>
                <Rect height={scrollElement.Phase2} />
              </Box>
              <Stick>
                <Progress height={scrollElement.Phase2} />
              </Stick>
            </Left>
            <Right height={scrollElement.Phase2}>
              <Name height={scrollElement.Phase2}>Equipping The Army</Name>
              <Desc height={scrollElement.Phase2}>- The Genesis</Desc>
              <Content height={scrollElement.Phase2}>
                The Goal: The smoothest fucking mint in history. Our army
                believes in loyalty and the rewards that come with it. Therefore
                our whitelisted supporters will be the only ones to have the
                exclusive rights to mint. Additionally, we are going to be
                airdropping Whitelist Tokens for the mint, therefore ensuring a
                fair mint for all our army members.
              </Content>
            </Right>
          </Phase>
          <Phase id="Phase3">
            <Left>
              <Box height={scrollElement.Phase3}>
                <Rect height={scrollElement.Phase3} />
              </Box>
              <Stick>
                <Progress height={scrollElement.Phase3} />
              </Stick>
            </Left>
            <Right height={scrollElement.Phase3}>
              <Name height={scrollElement.Phase3}>The Commandment</Name>
              <Desc height={scrollElement.Phase3}>
                - Divide, Conquer and Expand{" "}
              </Desc>
              <Content height={scrollElement.Phase3}>
                Apart from the glory of being a member of our army, our holders
                will have access to different perks, such as whitelist for
                future mints and/or collaborations with other projects to
                further expand their empire. Moreover, we hope to take care of
                our members not only economically but also socially. To achieve
                this we would want to find some crazy talented members from our
                community, whether its a crazy talented community organiser or a
                fierce developer who can push our roadmap even further, we want
                the best of the best and won’t comprise when it comes to this
                (meaning we will pay a boatload for it)
              </Content>
            </Right>
          </Phase>
          <Phase id="Phase4">
            <Left>
              <Box height={scrollElement.Phase4}>
                <Rect height={scrollElement.Phase4} />
              </Box>
              <Stick>
                <Progress height={scrollElement.Phase4} />
              </Stick>
            </Left>
            <Right height={scrollElement.Phase4}>
              <Name height={scrollElement.Phase4}>Enter the DAO</Name>
              <Desc height={scrollElement.Phase4}>- We’re still hungry...</Desc>
              <Content height={scrollElement.Phase4}>
                We know our Kaiju Army, still thirsts for more. To satiate the
                hunger of our ever growing community, through vetted alpha we
                want to create a holder DAO to facilitate decision- making and
                discussions in the crypto and NFT world. Our DAO will consist of
                our most loyal, skilled and brightest supporters of our
                community. They will be among the core members with special
                ranks and roles. Furthermore, they will be the ones who will be
                responsible for helping decide how to allocate resources for the
                greater good of our community.
              </Content>
            </Right>
          </Phase>
          <Phase id="Phase5">
            <Left>
              <Box height={scrollElement.Phase5}>
                <Rect height={scrollElement.Phase5} />
              </Box>
              <Stick>
                <Progress height={scrollElement.Phase5} />
              </Stick>
            </Left>
            <Right height={scrollElement.Phase5}>
              <Name height={scrollElement.Phase5}>
                Dominate Dominate Dominate
              </Name>
              <Desc height={scrollElement.Phase5}>
                - We don’t fucking stop.{" "}
              </Desc>
              <Content height={scrollElement.Phase5}>
                We have a large yet focused vision for our projects and
                community. As the KaijuVerse DAO grows, so will the ideas of the
                Kaiju’s. The future of the project lies in the hands of its
                holders. Possible new utility such as ‘Tokenomics’ and staking
                (possibly $KJU), or a breeding mechanic and other project
                developments to expand the Kaiju empire, our future direction we
                leave to army - the possibilities are limitless!
              </Content>
            </Right>
          </Phase>
          {/* </Fade> */}
        </ProgressWrap>
      </Wrap>
      <Wrap>
        <Title>KaijuVerse Team</Title>
        <Description>
          We’re a diverse group of creatives, artists, and developers, excited
          to build on the rapidly growing Solana ecosystem. You can find us on
          our Discord. We enjoy a good chat about almost anything!
        </Description>
        <Team>
          <TeamContainer>
            <a href="#l">
              <img src="/assests/Team Photos/General.gif" alt="" />
            </a>
            <MemberName>The General</MemberName>
            <Position>Project Lead</Position>
          </TeamContainer>
          <TeamContainer>
            <a href="#">
              <img src="/assests/Team Photos/Lieutenant.gif" alt="" />
            </a>
            <MemberName>Major Rydah</MemberName>
            <Position>Head of Design and Concept</Position>
          </TeamContainer>
          <TeamContainer>
            <a href="#">
              <img src="/assests/Team Photos/Commander.gif" alt="" />
            </a>
            <MemberName>Commander Ardy</MemberName>
            <Position>Head of Marketing, PR, and Collaborations</Position>
          </TeamContainer>
          {/* <TeamContainer>
            <a href="#">
              <img src="/assests/Team Photos/Chief.gif" alt="" />
            </a>
            <MemberName>Chief Mobster</MemberName>
            <Position>Community Manager</Position>
          </TeamContainer> */}
          <TeamContainer>
            <a href="#">
              <img src="/assests/Team Photos/Zeke.gif" alt="" />
            </a>
            <MemberName>Lieutenant Zeke</MemberName>
            <Position>Project Developer</Position>
          </TeamContainer>
        </Team>
      </Wrap>
    </Container>
  );
}

export default Section4;

const Container = styled.section`
  min-height: 100vh;
  background-color: #02080d;
  background-repeat: no-repeat;
  background-size: 100%;
  // min-width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Wrap = styled.div`
  margin: 32px 32px;
  // margin-top: 64px;
  display: flex;
  flex-direction: column;
  // align-items: flex-start;
  max-width: 951px;
  width: 100%;
  min-width: 260px;
`;

const Title = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  /* identical to box height, or 36px */
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 4px 0;
  margin-bottom: 36px;
  color: #9000ff;
`;

const Description = styled.p`
  // max-width: 951px;
  // // max-height: 88px;
  // width: 100%;
  margin: 0 32px;
  height: 100%;
  // min-width: 200px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0em;
  /* or 22px */

  //   text-align: justify;
  display: flex;
  justify-content: center;
  color: #ffffff;

  @media (max-width: 1100px) {
    font-size: 18px;
  }
`;

const ProgressWrap = styled.div`
  margin: 64px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const Phase = styled.section`
  display: flex;
  max-width: 951px;
  width: 100%;
  margin-bottom: 30px;
`;

const Left = styled.div`
  margin-top: 32px;
  margin-right: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 600px) {
    display: none;
  }
`;

const Box = styled.div`
  width: 40px;
  height: 40px;
  border: ${(props) =>
    props.height !== "0%" ? "3px solid #FFFFFF" : "3px solid #FFFFFF66"};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Rect = styled.div`
  width: 16px;
  height: 16px;
  background: ${(props) => (props.height !== "0%" ? "#FFFFFF" : "#FFFFFF66")};
`;

const Stick = styled.div`
  margin-top: 16px;
  position: relative;
  width: 6px;
  height: 220px;
  background: rgba(255, 255, 255, 0.25);
`;

const Progress = styled.div`
  position: absolute;
  width: 100%;
  height: ${(props) => props.height};
  // height: 60%;
  background: #ffffff;
`;

const Right = styled.div`
  padding: 20px 32px;
  margin: 0 16px;
  height: fit-content;
  max-width: 951px;
  width: 100%;
  border: 3px solid
    ${(props) => (props.height !== "0%" ? "#ffffff" : "#ffffff66")};

  ul {
    li {
      font-family: Poppins;
      font-style: normal;
      font-weight: 300;
      font-size: 20px;
      color: ${(props) => (props.height !== "0%" ? "#FFFFFF" : "#FFFFFF66")};
    }
  }
`;

const Name = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: ${(props) =>
    props.height !== "0%" ? "#9000ff" : "rgba(255, 255, 255, 0.4)"};
`;

const Desc = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: ${(props) => (props.height !== "0%" ? "#FFFFFF" : "#FFFFFF66")};
`;

const Content = styled.div`
  margin: 24px 0;
  max-width: 702px;
  width: 100%;
  min-width: 100px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 30px;
  color: ${(props) => (props.height !== "0%" ? "#FFFFFF" : "#FFFFFF66")};
`;

const Team = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 64px 0;
  @media (max-width: 1000px) {
    justify-content: center;
  }
`;

const TeamContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  img {
    transition: 0.3s;

    :hover {
      width: 300px;
      height: 300px;
    }
    width: 280px;
    height: 280px;
  }
  @media (max-width: 1000px) {
    margin: 32px 32px;
  }
`;

const MemberName = styled.div`
  font-family: Poppins;
  margin-top: 16px;
  margin-bottom: 6px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #9000ff;
`;

const Position = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: #ffffff;
  max-width: 200px;
  text-align: center;
`;
